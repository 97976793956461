import React from 'react';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Link from 'components/ui/link';
import { transformImages } from 'helpers/transformImages';
import arrow from 'img/ui/arrow_white.svg';

const UserJourneyMap = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query UserJourneyMap {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "user-experience/user-journey-map" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const images = transformImages(edges);
  return (
    <>
      <h2>What is a user journey – definition</h2>
      <p>
        A{' '}
        <Link
          href="https://en.wikipedia.org/wiki/User_journey#:~:text=A%252520user%252520journey%252520is%252520the,interacting%252520with%252520something%25252C%252520typically%252520software.&text=It%252520is%252520often%252520used%252520as,software%252520or%252520other%252520virtual%252520experiences"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          user journey
        </Link>{' '}
        is the cumulative experience of a user while interacting with your product. It is a
        well-detailed explanation of each step your users take to complete specific tasks within an
        application, system, or a website. This lets you discover the current user workflow, as well
        as uncovers improvement areas. A user journey also provides a precise timeline of user
        actions and a visualization of their product interactions. When the user journey is
        documented by design and product specialists who are working on enhancing a product or
        service, it is usually known as a
        <Link
          href="https://www.nngroup.com/articles/journey-mapping-101/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          {' '}
          user journey map
        </Link>
        .
      </p>
      <p>
        In the next section, we shed some light on the specific benefits of creating a map of user
        experiences.
      </p>
      <h2>The benefits of developing a user journey map</h2>
      <p>
        Are you looking to give your company a boost and increase product sales? If done efficiently
        and regularly, user journey mapping can be one of the most effective strategies for
        uplifting a business. Here are some of the major benefits of generating a user journey map:
      </p>
      <h3>Bringing the entire team on the same wavelength</h3>
      <p>
        <Link
          href="https://www.nngroup.com/articles/journey-mapping-101/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Mapping user experiences{' '}
        </Link>
        encourages collaboration and teamwork which aligns the goals and visualizations of everyone
        on board. Journey maps are a great option for delivering precise information that helps
        create a shared vision. These maps also form the basis of decision making as the entire team
        transitions into the process.
      </p>
      <Img
        fluid={images.team.node.childImageSharp.fluid}
        alt="Team"
        title="Bringing the entire team on the same wavelength"
      />
      <p>
        Source:{' '}
        <Link
          href="https://unsplash.com/photos/sWxKwsgY57c"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Unsplash
        </Link>
      </p>
      <h3>Showcasing the user journey as a complete package</h3>
      <p>
        A user journey is a long-drawn process that is completed by taking a series of
        <Link
          href="https://www.tandemseven.com/journey-mapping/effective-current-state-customer-journey-mapping-process/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          specific steps
        </Link>
        . User mapping showcases these steps as an entire journey. This helps the team in discussing
        the goals of the journey including business and end-user goals and their alignment with the
        goals of the organization.
      </p>
      <h3>Allowing for user scenario analysis</h3>
      <p>
        <Link href="/user-experience/" target="_blank" rel="noopener noreferrer">
          User experience
        </Link>{' '}
        mapping makes it easy to evaluate and understand various user scenarios. It enables the team
        to{' '}
        <Link
          href="https://www.nngroup.com/articles/analyze-customer-journey-map/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          test out several user journeys
        </Link>{' '}
        and select the best performing one via an informed process. Journey maps are best fitted for
        scenarios that involve a series of events such as shopping or taking a trip or describing a
        process that will involve a set of transitions over time.
      </p>
      <h3>Identifying the user journey loopholes</h3>
      <p>
        Journey mapping lets you recoil and observe the entire user journey process with a different
        lens. The weak links and loopholes that you were unable to see before now become apparent.
        This also helps you understand the problems experienced by your end-users when interacting
        with your product. On user maps, these points are generally represented as ‘dips’ in the
        journey diagram (see the example below), so you can spot potentially underperforming areas
        and compare them with other low points in the journey. This also gives you the ability to
        create a list of the much-needed optimizations.
      </p>
      <Img
        fluid={images.lindasjourneymap.node.childImageSharp.fluid}
        alt="Linda's journey map"
        title="Identify user journey"
      />
      <p>
        Source:{' '}
        <Link
          href="https://www.wordstream.com/blog/ws/2019/11/21/customer-journey-mapping"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Wordstream
        </Link>
      </p>
      <h3>Spotting unnecessary processes and touchpoints</h3>
      <p>
        Another great advantage of a user experience map is the ability to identify steps that can
        be disposed of to make the user experience more seamless and cohesive. Eliminating an
        existing step that is no longer required or adding an efficient step to the process can all
        be achieved with ease if you have a journey map.
      </p>
      <p>
        Now that you understand the benefits of building a user journey map, it’s time to learn how
        to create one!
      </p>
      <h2>The steps involved in building a customer journey map</h2>
      <h3>Step 1: Create comprehensive buyer personas </h3>
      <p>
        Buyer personas are a reflection of your real customers that help in understanding their
        needs and expectations. It is a good idea to start with baseline personas and then go on to
        creating multiple ones to get a deeper understanding of who your buyers are exactly. It also
        helps in introducing your workshop participants with the users involved in the customer
        journey.
      </p>
      <p>
        To get you inspired, here’s an example of a buyer persona from the Munro American brand:
      </p>

      <Img
        fluid={images.brandityler.node.childImageSharp.fluid}
        alt="Brandi Tyler - buyer person"
        title="Buyer persona from the Munro Amercan"
      />
      <p>
        Source:{' '}
        <Link
          href="https://blog.alexa.com/10-buyer-persona-examples-help-create/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Alexa
        </Link>
      </p>
      <h3>Step 2: Get a grasp of the user goals</h3>
      <p>
        Do you know what your users want to achieve with your products and services? If you are
        unsure, then getting a sense of your user goals should be your primary focus.
      </p>
      <p>
        During the creation of a journey map, set up activities to unleash customer goals and then
        place them in a logical sequence. Such an arrangement can be used to provide structure to
        the map as you determine its specific stages.
      </p>
      <p>
        Each stage can have one or more corresponding goals attached to it, and should be
        categorized as easy or difficult. As a result, you’ll be able to uncover any frustrations or
        unexpected positive surprises that customers experience throughout the journey. Once you’ve
        determined goals for all the different stages, you can then proceed to building your
        hypothetical user map.
      </p>
      <h3>Step 3: Map out the user’s touchpoints</h3>
      <p>
        Maps are a great method of communicating the alignment between your customer’s expectations
        and their experiences throughout the user journey. Therefore, it is imperative to identify
        all the points of contact and situations (both online and offline) that arise when a client
        meets your brand. As you’ll notice, some touchpoints carry more weight than others. For
        instance, a bad check-in experience at a hotel can surely spoil your entire stay!
      </p>
      <p>
        To avoid setbacks and keep your customers happy, it would be wise to keep a record of all
        potential touchpoints that occur between customers and your organization.
      </p>
      <h3>Step 4: Figure out customer pain points and frustrations</h3>
      <p>
        Now is the time to focus on determining your customers’ pain points and roadblocks. This can
        be achieved by asking yourself user-centered questions and interviewing your customers and
        the customer-facing staff. A few potential questions to ask would be:
      </p>
      <ul>
        <li>Can my customers achieve their goals with my website?</li>
        <li>What are problems and areas for improvement?</li>
        <li>At which part of the process are customers abandoning purchases and why?</li>
      </ul>
      <p>
        Once you know the roadblocks, mark them down on the user journey map. Also, jot down the
        areas where you are performing well but could use some improvements.
      </p>
      <p>
        If you want to dig deep into your users’ experiences, you can also refer to our list of{' '}
        <Link
          href="/usability-testing/usability-testing-questions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          24 usability testing questions
        </Link>
        .
      </p>
      <h3>Step 5: Prioritize and eliminate the roadblocks</h3>
      <p>
        Identify the parts that must be fixed immediately and ask yourself if there are any
        particularly problematic features. For example, giving your sign-up process a face-lift
        would be a good idea if your customers are frequently complaining about it. The good news
        is, you don’t always need to implement radical changes! Breaking everything down and
        starting from scratch might not be needed, and just a few minor changes will suffice to
        improve your product.
      </p>
      <h3>Step 6: Update and enhance</h3>
      <p>
        Last, but not least, make sure your user journey map is a living document and that it is not
        set in stone. Since your customers are constantly evolving, it is important to keep checking
        their satisfaction levels and improve the user journey map if such a need arises. If it is
        feasible, update and improve your journey map{' '}
        <Link
          href="https://www.invespcro.com/blog/customer-journey-maps/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          every six months or so
        </Link>
        . Also, keep in mind that whenever a significant change is introduced to your product, the
        customer journey map should be updated accordingly.
      </p>
      <h2>Three User Journey Mapping Examples</h2>
      <p>
        As beneficial as it is to create user journey maps for product improvement, marketing, and
        UX, there is also a bit of ambiguity surrounding it. To clarify the process, we will discuss
        a few customer journey mapping
        <Link
          href="https://cxl.com/blog/customer-journey-mapping-examples/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          {' '}
          examples
        </Link>{' '}
        of various companies that have benefitted from this process:
      </p>
      <h3>
        <Link href="https://www.leadfeeder.com/" target="_blank" rel="noopener noreferrer nofollow">
          Leadfeeder
        </Link>
      </h3>
      <p>
        This leading Finland-based analytics tool gives you a log of companies that visit your
        website. The company’s main vision is to incorporate web intelligence into businesses. As
        such, their user journey maps the paths from Discovery to Sales and Retention. By
        implementing mapping, they have identified their customer’s goals, touchpoints, success
        factors, and secondary KPIs.
      </p>
      <Img
        fluid={images.leadfeeder.node.childImageSharp.fluid}
        alt="Leadfeeder dashboard"
        title="Leadfeeder"
      />
      <p>
        Source:{' '}
        <Link href="https://www.leadfeeder.com/" target="_blank" rel="noopener noreferrer nofollow">
          Leadfeeder
        </Link>
      </p>
      <p>
        As an example, one of the customer’s goals during the discovery phase of the user journey is
        to identify a tool that tracks anonymous website users. During the free trial, the customer
        finds out how it works and if it meets their requirements. Once the customer buys the
        subscription, the key goal is to get accurate access to website data. Once this is met, the
        customer proceeds to the retention phase.
      </p>
      <h3>
        <Link href="https://ninjaoutreach.com/" target="_blank" rel="noopener noreferrer nofollow">
          NinjaOutreach
        </Link>
      </h3>
      <p>
        NinjaOutreach offers an all-purpose influencer marketing tool designed specifically for
        bloggers, small businesses, startups, digital marketers, and agencies. One of their phases,
        known as ‘day 3’, is based on the{' '}
        <Link
          href="https://cxl.com/blog/customer-journey-mapping-examples/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          following assumptions
        </Link>
        :
      </p>
      <ul>
        <li>
          “People need time to explore and use a new application, so if they don’t appear to be
          engaged in the first day or two, it’s probably natural.
        </li>
        <li>
          At the same time, people can easily forget about an application and become quickly
          disengaged, so if by day 3, 4, or 5 you are not seeing sufficient activity, then it may be
          cause for concern.
        </li>
        <li>
          <p>
            Often, applications might require something from the user to allow the user to really
            experience the full benefits. This might be something like:
          </p>
          <ul>
            <li style={{ marginBottom: 16 }}>Importing a list</li>
            <li style={{ marginBottom: 16 }}>Integrating with another application</li>
            <li>Filling out a profile</li>
          </ul>
        </li>
      </ul>
      <Img
        fluid={images.ninjaoutreach.node.childImageSharp.fluid}
        alt="Ninja Outreach profile"
        title="Ninja Outreach"
      />
      <p>
        Source:{' '}
        <Link
          href="https://ninjaoutreach.com/contact-relationship-management/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          NinjaOutreach
        </Link>
      </p>
      <h3>
        <Link
          href="https://www.elevatedthird.com/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          ElevatedThird.com
        </Link>
      </h3>
      <p>
        Judd Mercer, Creative Director at{' '}
        <Link href="https://www.elevatedthird.com/">Elevated Third,</Link> outlines{' '}
        <Link
          href="https://www.elevatedthird.com/news-insights/customer-journeys-real-estate"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          how to use journey maps in the real estate industry
        </Link>{' '}
        to develop your business.
      </p>
      <p>
        The company showcases six distinct phases for the user journey map, namely: Design,
        Financing, Construction, Leasing, Model, and Completion. Their map is a bit extensive, but
        they have modeled the entire process that a customer undertakes during the relationship.
      </p>
      <h2>Summary</h2>
      <p>
        A user journey map is a visual depiction of the entire customer experience. It provides
        ample benefits of viewing your product from a different perspective, identifying loopholes,
        analyzing differing user scenarios, and bringing the entire team on the same wavelength. If
        done efficiently, a user journey map is one of the most effective design tools and
        strategies for turning your product into a success.
      </p>
      <div className="next-chapter">
        <Link href="/user-experience/ux-prototyping/">
          <div>
            <p>Chapter 5</p>
            <p>All you need to know about effective UX prototyping</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUsabilityTestingTemplate(UserJourneyMap);
